// Path: src/home/index.css

import React from "react"
import "./index.css"
import request from "../utils"
import { LoadingSvg, TopTips } from "../utily"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { isToday } from "../utils"

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()

export default function Home() {

  const [isLoading, setIsLoading] = React.useState(false);

  const [wxId, setWxId] = React.useState('')
  const [age, setAge] = React.useState('')
  const [gender, setGender] = React.useState(1);

  const [ageWarning, setAgeWarning] = React.useState(false)
  const [wxIdWarning, setWxIdWarning] = React.useState(false)

  const [isTipsShow, setIsTipsShow] = React.useState(false)
  const [tipsText, setTipsText] = React.useState('')
  const [visitorID, setVisitorID] = React.useState('')

  const handleIdChange = (e) => {
    setIsTipsShow(false)
    setWxId(e.target.value)
  };

  const handleAgeChange = (e) => {
    setIsTipsShow(false)
    setAge(e.target.value);
  }

  const handleGenderChange = (e) => setGender(e.target.value)

  const validateAgeNumber = (age) => {
    const reg = /^[1-9]\d*$/;
    return reg.test(age);
  }

  const validateWxId = (wxId) => {
    const reg = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/;
    const regPhone = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
    return reg.test(wxId) || regPhone.test(wxId);
  }

  React.useEffect(() => {
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise
      const result = await fp.get()
      setVisitorID(result.visitorId)
      console.log("visitor_id ->", result.visitorId)
    })()
  }, [])

  React.useEffect(() => {

    let timer = null;
    if (wxIdWarning) {
      setIsTipsShow(true)
      setTipsText("此微信号不存在")
      return
    } else {
      setIsTipsShow(false)
    }

    if (ageWarning) {
      setIsTipsShow(true)
      setTipsText("年龄格式不正确")
      return
    } else {
      setIsTipsShow(false)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [ageWarning, wxIdWarning])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) {
      return
    }

    if (!validateWxId(wxId)) {
      setWxIdWarning(true)
      console.log("微信格式不正确")
      return
    } else {
      setWxIdWarning(false)
    }

    if (!validateAgeNumber(age)) {
      setAgeWarning(true)
      console.log("年龄格式不正确")
      return
    } else {
      setAgeWarning(false)
    }
    setIsLoading(true);
    // 获取访客信息
    fetchVisitorInfo(visitorID)
  }

  // 获取访客信息
  const fetchVisitorInfo = (visitorID) => {
    request({
      url: "/user/visitor/" + visitorID,
      method: "GET",
    }).then(res => {
      console.log(res)
      // 如果访客信息不存在，直接添加盒子
      switch (res.code) {
        case 0: // 访客信息存在
          // 如果访客今日没有抽取过，并且有剩余抽取次数, 直接跳转到抽取页面
          if (isToday(res.data.last_put_time * 1000) && res.data.rank > 0) {
            redirectTo("box")
          } 
          
          // 今天没有添加过盒子，则更新访客可抽取信息
          else if (!isToday(res.data.last_put_time * 1000)) {
            console.log("更新访客信息")
            updateVisitorInfo();
          } 

          // 今天添加过盒子，并且抽取过，则直接定位到活动火爆页面
          else {
            redirectTo("hot")
          }

          console.log(res.data)
          break;
        case 1: // 访客信息不存在
          addBox();
          break;
        default:
          break;
      }

    }).catch(err => {
      console.log(err)
    })
  }

  // 添加盒子
  const addBox = () => {
    let data = { gender: gender, wechat: wxId, age: age, depositor_id: visitorID };
    request({
      url: "/box",
      method: "PUT",
      data: data,
      needToken: true
    }).then(res => {
      setTimeout(() => {
        // 跳转到抽取页面
        setIsLoading(false);
        redirectTo("box");
      }, 1200)

      console.log(res)
    }).catch(err => {
      setIsLoading(false);
      console.log(err)
    })
  }
  
  const updateVisitorInfo = () => {
    let data = { visitor_id: visitorID };
    request({
      url: "/user/visitor/reset",
      method: "PATCH",
      data: data
    }).then(res => {
      console.log("update result ->", res)
      setIsLoading(false);
      redirectTo("box");
    }).catch(err => {
      console.log(err)
    })
  }

  function redirectTo(target) {
    if (target === "box") {
      window.location.href = "/box/" + visitorID;
    } else if (target === "hot") {
      window.location.href = "/hot";
    }
  }

  return (
    <div className="home-main">
      <div className="container">
        <div className="box">

          <TopTips show={isTipsShow} text={tipsText} />

          <div className="form-header weui-form__text-area">
            <h2 className="weui-form__title">制作盲盒</h2>
            <div className="weui-form__desc">填写的资料将会被制作为盲盒</div>
          </div>

          <div className="weui-cells form-wraper">

            <label className="weui-cell weui-cell_active weui-cell_select weui-cell_select-after">
              <div className="weui-cell__hd">
                <span className="weui-label">性别</span>
              </div>
              <div className="weui-cell__bd">
                <select className="weui-select" name="select2" onChange={handleGenderChange}>
                  <option value="1">男</option>
                  <option value="2">女</option>
                </select>
              </div>
            </label>

            <label className={wxIdWarning ? "weui-cell_warn weui-cell  weui-cell_active" : "weui-cell  weui-cell_active"}>
              <div className="weui-cell__hd"><span className="weui-label">微信号</span></div>
              <div className="weui-cell__bd">
                <input id="js_input1" className="weui-input" placeholder="填写本人微信号" value={wxId} onChange={handleIdChange} />
              </div>
            </label>

            <label className={ageWarning ? "weui-cell_warn weui-cell  weui-cell_active" : "weui-cell  weui-cell_active"}>
              <div className="weui-cell__hd"><span className="weui-label">年龄</span></div>
              <div className="weui-cell__bd">
                <input id="js_input1" className="weui-input" placeholder="填写本人年龄" value={age} onChange={handleAgeChange} />
              </div>
            </label>

          </div>
          <div className="weui-cells__title">盲盒规则：</div>
          <div className="weui-cells__title">1. 每人每天可以抽取一次 </div>
          <div className="weui-cells__title">2. 请不要为了多加人虚假投放，会封IP哦~</div>
          <div className="form-submit" onClick={handleSubmit}>
            <div className="weui-btn weui-btn_primary">
              {isLoading ? <LoadingSvg fill={"#fff"} /> : "提交"}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}