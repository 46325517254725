import './App.css';
import './weui.min.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './home';
import Box from './box';
import Thanks from './thanks';
import { HotMessage } from './message';

function App() {
  React.useEffect(() => {
    if (window.location.hostname === "www.togetherblackbox.com") {
      localStorage.setItem("baseURL", "https://api.togetherblackbox.com/api/v1");
    } else {
      localStorage.setItem("baseURL", "https://api-top.lookcos.cn/api/box");
    }
  }, [])
  return (
    <>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/box" element={<Box/>} />
        <Route path="/box/:visitor" element={<Box/>} />
        <Route path="/hot" element={<HotMessage/>} />
        <Route path="/thanks" element={<Thanks/>} />
       </Routes> 
    </>
  );
}

export default App;
