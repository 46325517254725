export function HotMessage() {
  return (
    <>
      <div className="page msg_warn js_show">
        <div className="weui-msg">
          <div className="weui-msg__icon-area"><i className="weui-icon-warn weui-icon_msg"></i></div>
          <div className="weui-msg__text-area">
            <h2 className="weui-msg__title">抽取失败</h2>
            <p className="weui-msg__desc">
              由于抽取的人数太多，现在系统有些卡壳，请过几分钟再来抽哦！
            </p>
          </div>
          <div className="weui-msg__tips-area">
          </div>
        </div>
        </div>
    </>
  )
}