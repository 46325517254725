import "./index.css";

import React, { useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactCanvasConfetti from "react-canvas-confetti";
import request from "../utils";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 1000,
};


export default function Box() {

  const { visitor } = useParams();
  const [isExtracted, setIsExtracted] = React.useState(false);

  React.useEffect(() => {
    request({
      url: `/user/visitor/${visitor}`,
      method: "GET"
    }).then(res => {
      if (res.data.rank <= 0) {
        window.location.href = "/hot";
      }
    })
  }, [visitor])

  const refAnimationInstance = useRef(null);
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);


  const [pageShow, setPageShow] = React.useState(false);
  const [pageType, setPageType] = React.useState("error");
  const [pageTitle, setPageTitle] = React.useState("");
  const [pageMsg, setPageMsg] = React.useState("");
  const [pageOptTitle, setPageOptTitle] = React.useState("");

  const [isUploaded, setIsUploaded] = React.useState(false);

  const extract = () => {
    // 抽取盲盒
    request({
      url: `/box/${visitor}`,
    }).then(res => {
      if (res.data) {
        // 抽取成功
        fire();
        setPageType("success");
        setPageTitle("抽取成功!");
        let gender = res.data.gender === '1' ? "男" : "女";
        const msg = `抽取成功! \n\n ${res.data.age}岁 ${gender}` + "\n微信号：" + res.data.wechat + "\n\n请主动添加对方微信并备注“盲盒”\n本平台只提供交友媒介，请自行思考，谨防诈骗！\n本页面仅显示一次，请务必截图保存！";
        setPageMsg(msg);
        setPageOptTitle("已保存");
        setPageShow(true);
      } else {
        // 没有匹配任选，重定位到hot页面
        window.location.href = "/hot";
      }
    })
  }


  function uploadShot() {


    return (
      <div className="upload-main">
        <div className="raffle-header weui-form__text-area">
          <h2 className="weui-form__title">领取盲盒</h2>
          <div className="weui-form__desc">还差一步就可以免费抽取盲盒啦！</div>
          <div className="weui-form__desc">记得关注我们的小红书</div>
          <div className="weui-form__desc">我们后续还会发布更多足不出门一个人就可以参与的活动，敬请期待❤️</div>
          <a rel="noreferrer" href="https://www.xiaohongshu.com/user/profile/62560a7a0000000010009a26" className="official" target="_blank">小红书官方账号</a>
        </div>
        <div className="weui-btn weui-btn_primary extract-btn" onClick={() => { setIsUploaded(true) }}>我已经关注啦</div>
      </div>
    )
  };

  function extractPage() {
    return (
      <>
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        <MsgPage isShow={pageShow} type={pageType} title={pageTitle} msg={pageMsg} optTitle={pageOptTitle} optBtnFn={() => { window.location.replace("/thanks") }} />
        <div className="raffle-header weui-form__text-area">
          <h2 className="weui-form__title">抽取盲盒</h2>
          <div className="weui-form__desc">缘分就在转角处</div>
        </div>

        <div className="raffle-wraper">
          <div className="raffle-box">
            {
              Array(9).fill(0).map((item, index) => {
                return <CardItem key={index} isExtracted={isExtracted} setIsExtracted={setIsExtracted} extract={extract} />
              })
            }
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="raffle-main">
        <div className="container">
          <div className="box">
            {isUploaded ? extractPage() : uploadShot()}
          </div>
        </div>
      </div>
    </>
  );
}


function MsgPage({ isShow, type, title, msg }) {
  /*
    isShow: 是否显示
    type: 类型，分为success和error
    title: 标题
    msg: 内容
    optTitle: 操作按钮标题
    optBtnFn: 操作按钮点击事件
  */

  const iconClassName = type === "success" ? "weui-icon-success weui-icon_msg" : "weui-icon-warn weui-icon_msg";

  return (
    <div className="page-msg page-success" style={{ opacity: isShow ? 1 : 0, zIndex: isShow ? 999 : -1 }}>
      <div className="weui-msg">
        <div className="weui-msg__icon-area"><i className={iconClassName}></i></div>
        <div className="weui-msg__text-area">
          <h2 className="weui-msg__title">{title}</h2>
          <p className="weui-msg__desc msg">{msg}</p>
        </div>
        <div className="weui-msg__opr-area">
          <p className="weui-btn-area">
            <div role="button" className="weui-btn weui-btn_disabled  weui-btn_primary ">已保存 按左上角按钮退出</div>
          </p>
        </div>
      </div>
    </div>
  )
}


function CardItem({ isExtracted, setIsExtracted, extract }) {
  const sign = <img src="/images/sign.png" alt="sign"></img>;
  const [content, setContent] = React.useState(sign);
  // 控制旋转
  const [isTap, setIsTap] = React.useState(false);

  const handleTap = () => {
    if (isExtracted) return;
    // 只能抽取一次
    setIsExtracted(true);
    setIsTap(true);

    // 动画播放
    let timer = null;
    timer = setTimeout(() => {
      setContent("🎉");
      let timer2 = null;
      timer2 = setTimeout(() => {
        extract();
        clearTimeout(timer2);
        clearTimeout(timer);
      }, 800);
    }, 520);

  }

  return (
    <div className={isTap ? "item item-rotate" : "item"} onClick={handleTap}>
      {content}
    </div>
  );
}